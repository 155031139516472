import { useEffect, useState } from "react";

export function getTimeRemaining(endtime) {
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
    const days = Math.floor(total / (1000 * 60 * 60 * 24));

    return {
        total,
        days,
        hours,
        minutes,
        seconds
    };
}

function useTimer(seconds = 600) {
    const [time, setTime] = useState(seconds);
    useEffect(() => {
        if (time === 0) return;
        const interval = setInterval(() => {
            setTime(time - 1);
        }, 1000);
        return () => clearInterval(interval);
    }, [time]);
    return { minutes: Math.floor(time / 60), seconds: time % 60, time: time };
}

export default useTimer;