'use client'

import React, { useEffect, useState } from 'react';
import Image from 'lib/image'
import Link from 'next/link';
import { Player } from "@lottiefiles/react-lottie-player";
import Button from 'components/utilities/button';
import useTimer, { getTimeRemaining } from 'lib/hooks/useTimer';

function Timer({ countdown }) {
    const [timer, setTime] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });

    useEffect(() => {
        const interval = setInterval(() => {
            setTime(getTimeRemaining(countdown))
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return timer.seconds > 0 ? <div className="flex items-center justify-center h-8 relative space-x-2">
        <p className='text-xs'>Prices increasing in </p>
        <span className="font-bold text-xs">
            {timer.days > 0 ? `${timer.days} d : ` : null}{timer.hours > 0 ? `${timer.hours} h : ` : null}{timer.minutes} m : {timer.seconds} s
        </span>
    </div> : null
}

export default function LabelBottomNavigation({ active, contact, countdown }) {
    const [isVisible, setIsVisible] = useState(true);

    useEffect(() => {
        if (contact) {
            let prevScrollY = window.pageYOffset;
            const handleScroll = () => {
                const currentScrollY = window.pageYOffset;
                if (prevScrollY > currentScrollY) {
                    setIsVisible(true);
                } else {
                    setIsVisible(false);
                }
                prevScrollY = currentScrollY;
            };

            window.addEventListener('scroll', handleScroll);

            return () => window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const buttons = [
        { name: 'Explore', icon: 'logo', route: '/' },
        { name: 'Search', icon: 'search', route: '/search' },
        { name: 'Wishlist', icon: 'wishlist', route: '/wishlist' },
        { name: 'More', icon: 'more', route: '/profile' },
    ];

    return <div>
        <>
            {isVisible ? <div className="h-16"></div> : null}
            {countdown ? <div className="h-8"></div> : null}
            {contact ? <div className="h-10"></div> : null}
        </>
        <nav className={`fixed rounded-t-2xl w-full bottom-0 z-20 bg-white backdrop-filter backdrop-blur-lg bg-opacity-90 border-t border-gray-200 transition-all duration-500 ease-in-out ${isVisible ? '' : (countdown ? 'translate-y-[50%]' : 'translate-y-[60%]')}`}>
            <div className="max-w-5xl mx-auto px-4">
                {countdown ? <Timer countdown={countdown} /> : null}
                {contact ? <div className="flex items-center justify-center h-10 border-b relative space-x-4">
                    <p className='text-xs'>Need Help?</p>
                    <a target="_blank" rel="noopener noreferrer" href="https://api.whatsapp.com/send?phone=917248727279&text=Hello Hire A Camp!">
                        <Button className="px-2 py-2 text-xs">WhatsApp Us</Button>
                    </a>
                    <a href={"tel:+917060015989"}>
                        <Button className="px-2 py-2 text-xs" color="bg-gray-800">Call Us</Button>
                    </a>
                </div> : null}
                <div className="flex items-center justify-between h-16">
                    {buttons.map((button, k) => <Link
                        key={button.route}
                        href={button.route}
                        className="px-3 flex flex-col items-center"
                        style={active === k ? { color: '#48BE4C' } : { color: '#8a8a8a' }}>

                        {active === k ? <Player
                            keepLastFrame
                            autoplay src={process.env.NEXT_PUBLIC_CDN + 'assets/animated/' + button.icon + '.json'} style={{ height: '32px', width: '32px' }}>
                        </Player> :
                            <Image src={'assets/animated/' + button.icon + '.png'} width={32} height={32} alt={button.name} />}
                        <span className={`text-xs ${active === k ? 'font-medium' : ''}`}>{button.name}</span>

                    </Link>)}
                </div>
            </div>
        </nav>
    </div>;
}
