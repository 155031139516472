'use client';

import Image from 'lib/image'
import Link from 'next/link'

import { FiSearch } from "react-icons/fi";
import useUser from "../../lib/useUser";

function Header(props) {
    const { user } = useUser()
    const primaryColor = props.transparent ? 'white' : 'primary';

    return (
        <div
            className={"h-20 py-4 w-screen " + (props.transparent ? "absolute top-0 z-20 " : "bg-white shadow ") + (props.fixed? "backdrop-filter backdrop-blur-lg bg-opacity-90":"")}>
            <div className='flex items-center justify-between container'>
                <div className="flex items-center">
                    <Link href="/">

                        <Image src={`assets/icons/${props.transparent ? 'hireacamp-white' : 'hireacamp'}.png`} width={115} height={20} />

                    </Link>
                    <Link
                        href="/partner"
                        className={`hidden h-12 w-44 ml-8 border-2 rounded-full border-${primaryColor} md:flex items-center justify-center`}>

                        <p className={`text-base font-medium text-${primaryColor}`}>Become a host</p>

                    </Link>
                </div>
                {props.nosearch ? null : <Link href="/search" legacyBehavior>
                    <div className="hidden md:block w-52 h-12">
                        <div
                            className="flex space-x-20 items-center justify-end h-12 py-1.5 pl-6 pr-1.5 bg-white shadow border rounded-full border-gray-200">
                            <p className="text-sm font-medium text-gray-400">Search</p>
                            <div className="w-9 h-9 bg-primary rounded-full flex items-center justify-center">
                                <FiSearch className="text-white" />
                            </div>
                        </div>
                    </div>
                </Link>}
                <div className="flex items-center">
                    <div className={`hidden ml-8 md:inline-flex cursor-pointer items-center ${props.transparent ? 'text-white' : 'text-gray-600'}`}>
                        <Link href={user?.isLoggedIn ? "/profile" : '/login'} legacyBehavior>
                            <Image className="rounded-full" src={`assets/icons/${props.transparent ? 'user-white' : 'user'}.png`} width={29}
                                height={30} />
                        </Link>
                        <p className="ml-2 text-base font-medium">{user?.isLoggedIn ? user.name.split(' ')[0] : 'Sign in/up'}</p>
                    </div>
                    <div className={`hidden ml-8 md:inline-flex items-center ${props.transparent ? 'text-white' : 'text-gray-600'}`}>
                        <Image src={`assets/icons/${props.transparent ? 'helpdesk-white' : 'helpdesk'}.png`} width={29} height={30} />
                        <p className="ml-2 text-base font-medium">Help</p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Header