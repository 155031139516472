import ButtonBase from '@material-ui/core/ButtonBase';
import SyncLoader from "react-spinners/SyncLoader"; // choose your lib

export default function Button(props) {
    return <div className={`${props.disabled?'cursor-not-allowed':'cursor-pointer'} relative inline-block`}>
        <ButtonBase onClick={props.onClick} type={props.type || 'submit'} disabled={props.disabled} className={`px-6 py-3 font-medium leading-tight rounded-lg shadow hover:bg-opacity-90 hover:shadow-lg focus:outline-none focus:ring-0 transition duration-150 ease-in-out text-white ${props.className} ${props.disabled?'bg-gray-300': props.color || 'bg-primary'}`}>
            <div className={props.disabled?'opacity-60':''}>{props.children}</div>
        </ButtonBase>
        <div className="buttonProgress">
            {props.loading ? <SyncLoader
                color={props.loaderColor || '#fff'}
                size={10}
                loading={props.loading}
            /> : null}
        </div>
    </div>
}