'use client'

import Head from "next/head";
import Link from "next/link"
import Navbar from "components/navs/header";

import { Player } from "@lottiefiles/react-lottie-player";
import LabelBottomNavigation from 'components/navs/bottomnav';

export default function Custom404() {
    return <>
        <Head>
            <title>Page never returned from Camping | Hire A Camp</title>
        </Head>
        <Navbar />
        <div className="container flex flex-col items-center mt-12">
            <div className="h-72 w-96 relative">
                <Player
                    autoplay
                    loop
                    src="https://assets2.lottiefiles.com/packages/lf20_r71cen62.json"
                    style={{ height: '288px', width: '384px' }}
                >
                </Player>
            </div>
            <div className="inline-flex flex-col space-y-5 items-center mt-8 text-center">
                <p className="md:text-6xl text-4xl font-semibold text-black">Uh Oh! Error 404</p>
                <p className="text-2xl font-medium text-black">Looks like you got lost on an abandoned campsite</p>
                <div>
                    <Link href="/" legacyBehavior>
                        <div
                            className="flex items-center justify-center flex-1 px-16 pt-4 pb-3 bg-primary rounded-lg cursor-pointer">
                            <p className="text-lg font-semibold text-white">Go back Home</p>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
        <LabelBottomNavigation/>
    </>;
}
